<script>
import PageHeaderCompact from "@/components/layouts/content/page-header-compact.vue";
import paginateMixin from "@/mixins/PaginateMixin";
import citasService from "@/services/citasService";
import DataGrid from "@/components/common/utilities/DataGrid/DataGrid.vue";
import dayjs from "dayjs";
import ModalOrdenTrabajo from "@/views/historias/base/baseOrdenTrabajo/modalOrdenTrabajo.vue";
import ordenTrabajoService from "@/services/ordenTrabajoService";
import Toast from "@/components/common/utilities/toast";

export default {
  components: { ModalOrdenTrabajo, DataGrid, PageHeaderCompact },
  mixins: [paginateMixin(citasService)],
  created() {
    this.pagination.params.appointmentType = this.$route.params.appointmentType;
    this.index();
    // interval to refresh data
    this.interval = setInterval(() => {
      this.index();
    }, 20000);
  },
  data() {
    return {
      interval: null,
      appointmentType: "",
      appointments: [],
      headers: [
        "#",
        "Fecha",
        "Hora",
        "Usuario",
        "Sede",
        "Profesional",
        "Tipo Cita",
        "Estado",
        "",
      ],
      pagination: {
        params: {
          appointmentType: "",
          fecha: dayjs().format("YYYY-MM-DD"),
          estado: ["confirmada"],
        },
      },
    };
  },
  methods: {
    mapAppointmentState(state) {
      return {
        asignada: {
          rowClass: "",
          badgeClass: "badge badge-marketing badge-light",
          badgeIcon: "fa fa-circle fa-fw",
        },
        confirmada: {
          rowClass: "border-left-success border-left-lg",
          badgeClass: "badge badge-marketing badge-success",
          badgeIcon: "fa fa-check-double fa-fw",
        },
        atendida: {
          rowClass: "border-left-secondary border-left-lg",
          badgeClass: "badge badge-marketing badge-secondary",
          badgeIcon: "fas fa-stethoscope fa-fw",
        },
        cancelada: {
          rowClass: "border-left-danger border-left-lg",
          badgeClass: "badge badge-marketing badge-danger",
          badgeIcon: "fas fa-times fa-fw",
        },
        inasistente: {
          rowClass: "border-left-danger border-left-lg",
          badgeClass: "badge badge-marketing badge-warning",
          badgeIcon: "fas fa-exclamation-circle fa-fw",
        },
        desistida: {
          rowClass: "border-left-orange border-left-lg",
          badgeClass: "badge badge-marketing badge-warning",
          badgeIcon: "fas fa-exclamation-circle fa-fw",
        },
      }[state];
    },
    openWorkOrderModal(id_usuario, id_cita) {
      this.$refs.modalOrdenTrabajo.open(id_usuario, id_cita);
    },
    edit(id) {
      this.$refs.modalOrdenTrabajo.edit(id);
    },
    async handleSaved($event) {
      await citasService.atendida($event.id_cita);
      this.index();
      console.log($event);
    },
    async printTicket(id_orden_trabajo) {
      try {
        this.LoaderSpinnerShow();
        //window.open(await ordenTrabajoService.printTicket(id_orden_trabajo),'_blank', 'left=100,top=100,width=500,height=500');
        const pdfUrl = await ordenTrabajoService.printTicket(id_orden_trabajo);
        const zoomedPdfUrl = `${pdfUrl}#zoom=150`;
        window.open(
          zoomedPdfUrl,
          "_blank",
          "left=100,top=100,width=500,height=500"
        );
        this.LoaderSpinnerHide();
      } catch (e) {
        this.LoaderSpinnerHide();
        console.error(e);
        Toast.fire({
          icon: "error",
          title: "Ocurrio un error al generar el ticket",
        });
      }
    },
  
  },
  computed: {
    citas: function() {
      return this.pagination.data.map((cita) => {
        return {
          citaId: cita.id,
          id: `${cita.id}`.padStart(4, "0"),
          id_usuario: cita.usuario.id,
          edad: `${cita.usuario.years}`,
          documento: `${cita.usuario.tipo_documento.codigo} ${cita.usuario.documento}`,
          usuario: `${cita.usuario.nombre_1} ${cita.usuario.nombre_2 || ""} ${
            cita.usuario.apellido_1
          } ${cita.usuario.apellido_2 || ""}`.toUpperCase(),
          sede: `${cita.agenda.sede.NOMBRE_SEDE}`.toUpperCase(),
          estado: `${cita.estado}`,
          fecha: cita.fecha_cita,
          profesional: `${cita.agenda.professional.name}`.toUpperCase(),
          nombreTipoCita: `${cita.tipo_cita.nombre}`.toUpperCase(),
          tipoCita: cita.tipo_cita,
          facturas: cita.factura_count,
          hora_inicio: dayjs(cita.fecha_cita + " " + cita.hora_inicio).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          hora: `${dayjs(cita.fecha_cita + " " + cita.hora_inicio).format(
            "hh:mm A"
          )} - ${dayjs(cita.fecha_cita + " " + cita.hora_fin).format(
            "hh:mm A"
          )}`,
          isEnabled: dayjs(cita.fecha_cita + " " + cita.hora_inicio).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          countWorkOrders: cita.ordenes_trabajo.length,
          workOrderId:
            cita.ordenes_trabajo.length > 0 ? cita.ordenes_trabajo[0].id : null,
          workOrderInfo:
            cita.ordenes_trabajo.length > 0 ? cita.ordenes_trabajo[0] : null,
        };
      });
    },
  },
  onDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<template>
  <main>
    <page-header-compact>
      Citas Asignadas
    </page-header-compact>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="small font-weight-bolder">Fecha</label>
                    <input
                      v-model="pagination.params.fecha"
                      class="form-control form-control-sm"
                      type="date"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-auto">
                  <div class="form-group">
                    <button
                      class="btn btn-info mt-2 btn-sm shadow-sm"
                      @click="search()"
                    >
                      <i class="fa fa-filter fa-fw"></i> Filtar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <div class="card card-header-actions">
            <div class="card-header">
              <div>
                <!--checkbox inline for citas state-->
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="confirmada"
                    value="confirmada"
                    v-model="pagination.params.estado"
                    checked
                  />
                  <label class="form-check-label" for="confirmada"
                    >Confirmada</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="atendida"
                    value="atendida"
                    v-model="pagination.params.estado"
                  />
                  <label class="form-check-label" for="atendida"
                    >Atendida</label
                  >
                </div>
              </div>
            </div>
            <div class="card-body">
              <data-grid :data="pagination" :search-box="true" @search="search">
                <template #grid>
                  <!-- bs4 list-group -->
                  <ul class="list-group mb-2">
                    <li
                      v-for="(cita, i) in citas"
                      :key="`${i}_agenda_data_row`"
                      :class="mapAppointmentState(cita.estado).rowClass"
                      class="list-group-item flex-column align-items-start"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1">
                          <strong>
                            <i class="fas fa-calendar fa-fw"></i>
                            {{ cita.fecha | formatDate }}
                            <i class="fas fa-clock fa-fw"></i>
                            {{ cita.hora }}
                            <span
                              class="ml-2 badge badge-light badge-marketing rounded-pill font-weight-bolder"
                            >
                              <i class="fas fa-file-medical fa-fw"></i>
                              {{ cita.nombreTipoCita }}
                            </span>
                          </strong>
                          <small class="d-block font-weight-bolder mt-1">
                            <i class="fas fa-user fa-fw"></i> ({{
                              cita.documento
                            }}) {{ cita.usuario }} | {{ cita.edad }}</small
                          >
                        </h6>
                        <div>
                          <template v-if="cita.countWorkOrders">
                            <small
                              v-if="cita.workOrderInfo.resultado"
                              class="font-weight-bolder text-success"
                              >Resultados registrados</small
                            >
                          </template>
                          <span
                            class="badge badge-marketing badge-primary ml-2 mr-2 cursor-pointer"
                            v-if="cita.countWorkOrders"
                            @click="printTicket(cita.workOrderId)"
                          >
                            <i class="fas fa-flask fa-fw"></i>
                            {{ cita.workOrderInfo.toma_muestra }}
                          </span>
                          <span
                            :key="cita.id"
                            :class="mapAppointmentState(cita.estado).badgeClass"
                          >
                            <i
                              :class="
                                mapAppointmentState(cita.estado).badgeIcon
                              "
                            ></i>
                            {{ `${cita.estado}`.toUpperCase() }}
                          </span>
                          <!-- si tiene resultados -->
                        </div>
                      </div>
                      <div class="d-flex w-100 justify-content-between">
                        <p class="mb-1 font-weight-bolder">
                          Profesional y Sede :
                          <i class="fas fa-user-md fa-fw"></i>
                          {{ cita.profesional }}
                          |
                          <i class="fas fa-hospital fa-fw"></i>
                          {{ cita.sede }}
                        </p>
                        <div>
                          <!-- manejo de citas -->
                          <button
                            v-if="
                              cita.estado === 'confirmada' &&
                                cita.countWorkOrders === 0
                            "
                            class="btn btn-info btn-icon shadow-sm btn-sm mr-2"
                            title="Orden de Trabajo"
                            @click="
                              openWorkOrderModal(cita.id_usuario, cita.citaId)
                            "
                          >
                            <!-- laboratory fontawesome icon -->
                            <i class="fas fa-flask fa-fw"></i>
                          </button>
                          <button
                            v-if="cita.countWorkOrders > 0"
                            class="btn btn-info btn-icon shadow-sm btn-sm mr-2"
                            title="Orden de Trabajo"
                            @click="edit(cita.workOrderInfo.id)"
                          >
                            <!-- edit fontawesome icon -->
                            <i class="fas fa-edit fa-fw"></i>
                          </button>
                          <router-link
                            :to="{
                              name: 'resultados.laboratorio.create',
                              params: {
                                workOrderId: cita.workOrderId,
                              },
                              query: {
                                orderDate: cita.fecha,
                              },
                            }"
                            target="_blank"
                            v-if="cita.countWorkOrders > 0"
                            class="btn btn-primary btn-icon shadow-sm btn-sm"
                            title="LLenar Resultados"
                          >
                            <!-- laboratory fontawesome icon -->
                            <i class="fas fa-flask fa-fw"></i>
                          </router-link>
                        </div>
                      </div>
                    </li>
                  </ul>
                </template>
              </data-grid>
            </div>
          </div>
        </div>
      </div>
      <modal-orden-trabajo
        ref="modalOrdenTrabajo"
        v-on:saved="handleSaved"
        v-on:ticket="printTicket"
      ></modal-orden-trabajo>
    </div>
  </main>
</template>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
