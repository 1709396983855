import { render, staticRenderFns } from "./citasAsignadas.vue?vue&type=template&id=137fd724&scoped=true"
import script from "./citasAsignadas.vue?vue&type=script&lang=js"
export * from "./citasAsignadas.vue?vue&type=script&lang=js"
import style0 from "./citasAsignadas.vue?vue&type=style&index=0&id=137fd724&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137fd724",
  null
  
)

export default component.exports